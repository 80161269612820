<template>
  <div
    class="absolute h-full flex items-center -z-10 left-10 -top-10"
  >
    <label
      for="validation"
      class="h-0 w-0 overflow-hidden"
      tabindex="-1"
    >
      Validation input label
    </label>
    <input
      id="validation"
      ref="hiddenValidationInput"
      type="text"
      required
      class="focus:outline-none caret-transparent text-transparent"
      :value="value"
      autocomplete="off"
      tabindex="-1"
    >
  </div>
</template>

<script setup lang="ts">
interface Props {
  value?: string | null
  errorMessage: string | null
}

const props = defineProps<Props>()

const hiddenValidationInput = ref<HTMLInputElement | null>(null)
const setValidityMessage = (): void => {
  if (!hiddenValidationInput.value || !props.errorMessage || props.value) {
    return
  }
  hiddenValidationInput.value?.setCustomValidity(props.errorMessage)
}

const removeValidityMessage = (): void => {
  if (!hiddenValidationInput.value) {
    return
  }
  hiddenValidationInput.value?.setCustomValidity('')
}

watch(hiddenValidationInput, (): void => {
  setValidityMessage()
},
{
  immediate: true
})

watch(() => props.value, (): void => {
  if (!props.value) {
    return
  }
  removeValidityMessage()
},
{
  deep: true,
  immediate: true
})
</script>
