<template>
  <div
    v-auto-animate
    :class="[isChecked ? 'border-2 border-16-Agency-500' : 'border-17-Grey-32% cursor-pointer']"
    class="w-full border rounded-2xl py-5 focus:outline-none focus:shadow-focus"
    @click="onStoreSelect"
    @keydown.space.prevent="onStoreSelect"
  >
    <div
      v-auto-animate
      class="flex flex-col justify-between px-5"
    >
      <div>
        <h3
          class="font-roc-grotesk-medium text-Light-01-Text-Primary text-base leading-7"
        >
          {{ name }}
        </h3>
        <p
          class="font-plex text-Light-02-Text-Secondary text-2xs mt-1 leading-6"
        >
          {{ opening_hours }}
        </p>
        <p
          class="font-plex text-Light-02-Text-Secondary text-2xs mt-2 leading-6"
        >
          {{ address }}
        </p>
      </div>
      <div
        v-if="map_iframe_url && isChecked"
        v-auto-animate
        class="mt-5"
      >
        <div
          v-if="isGMapsActive"
          v-auto-animate
          class="relative h-36"
        >
          <div
            v-if="isIFrameLoading"
            class="w-full h-full flex items-center justify-center"
          >
            <IconLoader
              class="w-6 animate-spin"
              name="progress_agency"
            />
          </div>
          <iframe
            class="w-full h-36 rounded-2xl"
            :class="{ 'opacity-0': isIFrameLoading }"
            :src="map_iframe_url"
            @load="isIFrameLoading = false"
          />
        </div>
        <div
          v-else
          v-auto-animate
          class="relative flex justify-center items-center w-full h-36 z-0"
          @click.stop
        >
          <IconLoader
            name="map"
            class="rounded-2xl h-36 absolute w-full"
          />
          <span
            class="z-10 font-roc-grotesk text-16-Agency-600 text-xs p-5 cursor-pointer"
            @click="cookieConsent.openCookieComply"
          >
            {{ $t('store_selection.activate_maps') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Store } from '~/types/Store'
import { acceptedServices } from '~/utils/tracking-utils'

interface Props extends Store {
  modelValue?: Store | null
}

const props = defineProps<Props>()

interface Emits {
  (e: 'update:modelValue', value: Store): void
}
const emit = defineEmits<Emits>()

const isChecked = computed((): boolean => props.modelValue?.id === props.id)

const onStoreSelect = (): void => {
  emit('update:modelValue', props)
}

const isGMapsActive = computed((): boolean => acceptedServices.value.includes('gm') || acceptedServices.value.includes('all'))
const isIFrameLoading = ref<boolean>(true)
</script>
