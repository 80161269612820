<template>
  <div
    class="w-full py-5 border rounded-2xl border-17-Grey-32% cursor-pointer"
  >
    <div
      class="flex flex-col justify-between px-5 space-y-5"
    >
      <div
        class="space-y-4"
      >
        <h3
          class="w-1/4 h-7 rounded-lg bg-gradient-to-r from-07-Grey-300 to-07-Grey-100 animate-pulse"
        />
        <p
          class="w-1/2 h-6 rounded-lg bg-gradient-to-r from-07-Grey-300 to-07-Grey-100 animate-pulse"
        />
        <p
          class="w-3/4 h-6 rounded-lg bg-gradient-to-r from-07-Grey-300 to-07-Grey-100 animate-pulse"
        />
      </div>
      <div
        class="w-full h-36 rounded-2xl bg-gradient-to-r from-07-Grey-300 to-07-Grey-100 animate-pulse"
      />
    </div>
  </div>
</template>
